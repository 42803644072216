import React from 'react';

function Resume() {
  return (
    <iframe style={{ width: '22cm', height: '30.7cm' }} src="./Bartlomiej-Kus-Resume.pdf" /*sandbox="allow-scripts allow-modals"*/>
      {' '}
    </iframe>
  );
}

export default Resume;
